export const environment = {
  production: false,
  glassApiUrl: 'https://api.dev.glass-stream.com/api',
  loginCallback: 'https://www.dev.glass-stream.com/login/callback',
  logoutCallback: 'https://www.dev.glass-stream.com/logout',
  oktaIssuer: 'https://wmbiz.oktapreview.com',
  envName: 'dev',
  graphEnvName: 'dev',
  adminRole: 'STAGE|TWC|Glass OIDC - Dev|GlassAdmin',
  betaFeatureRole: 'STAGE|TWC|Glass OIDC - Dev|GlassFeatureTester',
  opsRole: 'STAGE|TWC|Glass OIDC - Dev|GlassOps',
  topDebug: true,
  slackUrl: 'slack://channel?team=E7T3R16EM&id=C01JNU0HT5Y',
  serviceDeskUrl: 'https://wbddigital.atlassian.net/servicedesk/customer/portal/6',
};
