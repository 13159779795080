import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { version as app_version } from 'src/environments/version';
@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.css'],
})
export class ContentLayoutComponent implements OnInit {
  version = app_version;
  year = new Date().getFullYear();
  url = '';
  disableNavbarTooltip = true;
  public adminRole = env.adminRole;
  public opsRole = env.opsRole;
  ngOnInit(): void {
    return;
  }

  constructor(private readonly router: Router) {
    // fetch current url to hide navbar from main page
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((e) => {
      this.url = e.url;
    });

    // listen to screen size to re-layout navbar
    this.checkScreenWidth();
  }

  @HostListener('window:resize', ['$event'])
  checkScreenWidth() {
    const { innerWidth } = window;
    // console.log('innerWidth', innerWidth);
    // magic number found in .css file
    const widthBreakpoint = 1150;
    this.disableNavbarTooltip = innerWidth > widthBreakpoint;
  }
}
