import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AdminGuard } from '@core/guards/admin-guard/admin-guard.guard';
import { LogoutComponent } from '@shared/components/logout/logout.component';
import { OpsGuard } from '@core/guards/ops-guard/ops-guard.guard';
const normalGuards = [OktaAuthGuard];
const routes: Routes = [
  {
    path: '',
    title: 'GLASS',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule),
        canActivate: normalGuards,
      },
      {
        path: 'watch',
        title: 'GLASSwatch',
        loadChildren: () => import('@modules/watch/watch.module').then((m) => m.WatchModule),
        canActivate: normalGuards,
      },
      {
        path: 'watch/multiview',
        title: 'GLASSwatch - Multiview',
        loadChildren: () => import('@modules/multiview/multiview.module').then((m) => m.MultiviewModule),
        canActivate: normalGuards,
      },
      {
        path: 'watch/stats',
        title: 'GLASSwatch - Stats',
        loadChildren: () => import('@modules/stats/stats.module').then((m) => m.StatsModule),
        canActivate: normalGuards,
      },
      {
        path: 'faq',
        title: 'GLASS - FAQ',
        loadChildren: () => import('@modules/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'tools',
        title: 'GLASS - Tools',
        loadChildren: () => import('@modules/tools/tools.module').then((m) => m.ToolsModule),
      },
    ],
    canLoad: normalGuards,
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'admin',
    title: 'GLASSadmin',
    component: ContentLayoutComponent,
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
    canLoad: [AdminGuard],
  },
  {
    path: 'ops/v2',
    title: 'GLASSops',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/ops_v2/ops.module').then((m) => m.Opsv2Module),
        canActivate: [OpsGuard],
        canLoad: [OpsGuard],
      },
    ],
  },
  {
    path: 'ops/v1',
    title: 'GLASSops',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/ops/ops.module').then((m) => m.OpsModule),
        canActivate: [OpsGuard],
        canLoad: [OpsGuard],
      },
    ],
  },
  {
    path: '**',
    component: ContentLayoutComponent,
    loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule),
    canActivate: normalGuards,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
