<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
@if (this.brandTemplate) {
  @if (this.templateId) {
    <div #templateEditor class="flex flex-col w-full h-full p-0 m-0">
      <p-steps [model]="steps" [readonly]="false" styleClass="mb-2" [activeIndex]="activeStepIndex" (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
      @if (this.activeStepIndex == 0) {
        <div class="flex flex-col w-full h-full mb-4 gap-y-4">
          <div class="flex flex-col items-center justify-center w-full h-full mt-4 mb-2 ml-auto gap-y-2 bg-glass-800">
            <label for="templateNameInput">Template Name:</label>
            <input id="templateNameInput" type="text" pInputText (change)="this.defile()" [(ngModel)]="brandTemplate.title" placeholder="Please enter a template name" class="mb-4 w-96" />
            @if (this.templateId && this.templateId !== 'new') {
              <div class="flex flex-row items-center">
                <p-checkbox inputId="activeSwitch" [binary]="true" [(ngModel)]="this.brandTemplate.active" (change)="this.defile()" />
                <label for="activeSwitch" class="ml-2">Active</label>
              </div>
            }
          </div>
          <!-- button container-->
          <div class="grid w-full grid-cols-3 p-2 pl-4 pr-4">
            <span></span>
            <p-button class="justify-self-center" label="SAVE CHANGES" [disabled]="this.brandTemplate.title.trim() == ''" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" [disabled]="this.brandTemplate.title.trim() == ''" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        </div>
      } @else if (this.activeStepIndex == 1) {
        <div class="flex flex-col w-full h-full mb-4 gap-y-4">
          <div class="flex w-full h-full bg-glass-700">
            <!-- Stages & Steps-->
            <div class="flex flex-col w-[30%] p-2">
              <div class="flex w-full p-2 mb-2 bg-glass-800">Stages for "{{ this.brandTemplate.title || '' }}"</div>
              <ul [dragula]="this.DragGroup.STAGES" [(dragulaModel)]="this.brandTemplate.stages" class="flex flex-col overflow-y-auto text-xl grow basis-0">
                @for (stage of this.brandTemplate.stages; track stage; let i = $index) {
                  <li class="flex items-center w-full">
                    <span [ngClass]="{ invisible: this.brandTemplate.stages.length === 1 }" class="handle material-symbols-rounded hover:cursor-move">drag_indicator</span>
                    <div class="stageGroupRow" (click)="this.selectStage(stage)" [ngClass]="this.selectedStage === stage ? 'selectedStage' : ''">
                      <div class="flex items-center w-full">
                        <input pInputText (change)="this.defile()" id="nameInput" class="flex-auto" type="text" [(ngModel)]="stage.name" [readOnly]="this.selectedStage !== stage" />
                        <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteStage($event, i)"></i>
                      </div>
                      <div class="flex items-center w-full mt-1">
                        <label class="ml-2">
                          <p-checkbox [binary]="true" (onChange)="checked($event)" [(ngModel)]="stage.reportable"></p-checkbox>
                          Enable in LiveView Report
                        </label>
                      </div>
                    </div>
                    <span class="stageRowMarker"></span>
                  </li>
                }
              </ul>
              <div class="mt-2">
                <p-button label="Add New Stage" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.addStage()"></p-button>
              </div>
            </div>
            <div class="flex flex-row w-[70%] p-2 border-2 border-gold rounded">
              <div class="flex flex-col w-full h-full" #stageDetails>
                <div class="flex w-full p-2 mb-2 bg-glass-800">Tasks for "{{ this.selectedStage?.name || '' }}"</div>
                <div class="flex flex-row flex-wrap col-12">
                  <div class="flex col-1">Order</div>
                  <div class="flex col-3">Team</div>
                  <div class="flex col-8">Instruction</div>
                </div>
                @if (this.selectedStage) {
                  <div [dragula]="this.DragGroup.TASKS" [(dragulaModel)]="this.selectedStage.steps" class="flex flex-col w-full h-full overflow-y-auto grow basis-0">
                    @for (step of this.selectedStage.steps; track step; let i = $index) {
                      <div class="stageGroupRow">
                        <div class="flex items-center w-full">
                          <div class="flex items-center col-1">
                            <span [ngClass]="{ invisible: this.selectedStage.steps.length === 1 }" class="mr-1 handle material-symbols-rounded hover:cursor-move">drag_indicator</span>
                            {{ i + 1 }}
                          </div>
                          <div class="col-3">
                            <p-dropdown appendTo="body" optionValue="teamCode" styleClass="w-full" optionLabel="teamName" [options]="this.brandTeamOptions" [(ngModel)]="step.team"></p-dropdown>
                          </div>
                          <div class="flex flex-row col-8 flex-nowrap">
                            <input type="text" pInputText class="flex-auto" (change)="this.defile()" [(ngModel)]="step.instruction" />
                            <p-button class="ml-3" icon="pi pi-times" (onClick)="this.deleteStep($event, i)"></p-button>
                          </div>
                        </div>
                        <div class="flex w-full">
                          <div class="flex col-1">&nbsp;</div>
                          <div class="flex items-center gap-1 col-11">
                            <label><p-checkbox styleClass="mr-1" [binary]="true" [(ngModel)]="step.reportable"></p-checkbox>Override LiveView Label</label>
                            @if (step.reportable) {
                              <div class="flex items-center flex-auto ml-3">
                                <input id="liveview-override" class="flex-auto w-full" type="text" pInputText [(ngModel)]="step.reportableLabel" placeholder="override instruction text (will display in all caps)">
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div class="mt-2">
                  <p-button label="Add New Task" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.addStep()"></p-button>
                </div>
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="grid w-full grid-cols-3 p-2 pl-4 pr-4">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        </div>
      } @else if (this.activeStepIndex == 2) {
        <div class="flex flex-col w-full h-full mb-4 gap-y-4">
          <div class="flex w-full h-full bg-glass-700 gap-x-4">
            <!-- Teams/Services -->
            <div class="flex flex-col w-[50%] p-2">
              <div class="flex-none w-full p-2 mb-2 font-bold bg-glass-800">Teams/Services GYR Checkpoint Items for "{{ this.brandTemplate.title || '' }}"</div>
              <ul class="flex-auto overflow-y-auto">
                @for (team of this.brandTemplate.checkpoint.teams; track trackByIndexFn(i, team); let i = $index; let length = $count) {
                  <li class="flex items-center w-full">
                    <div class="stageRow">
                      <input pInputText (change)="this.defile()" class="flex-auto" type="text" [(ngModel)]="this.brandTemplate.checkpoint.teams[i]" />
                      <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteTeam($event, i)"></i>
                    </div>
                  </li>
                }
              </ul>
              <div class="flex-none mt-2">
                <p-button label="Add New Team/Service" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.brandTemplate.checkpoint.teams.push('')"></p-button>
              </div>
            </div>
            <div class="flex flex-col w-[50%] p-2">
              <div class="flex-none w-full p-2 mb-2 font-bold bg-glass-800">Reportable Metrics (KPIs) for "{{ this.brandTemplate.title || '' }}"</div>
              <ul class="flex-auto overflow-y-auto">
                @for (metric of this.brandTemplate.checkpoint.metrics; track trackByIndexFn(i, metric); let i = $index; let length = $count) {
                  <li class="flex items-center w-full">
                    <div class="stageRow">
                      <input pInputText (change)="this.defile()" class="flex-auto" type="text" [(ngModel)]="this.brandTemplate.checkpoint.metrics[i]" />
                      <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteMetric($event, i)"></i>
                    </div>
                  </li>
                }
              </ul>
              <div class="flex-none mt-2">
                <p-button label="Add New Metrics" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.brandTemplate.checkpoint.metrics.push('')"></p-button>
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="grid w-full grid-cols-3 p-2 pl-4 pr-4">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <span></span>
          </div>
        </div>
      }
    </div>
  }
} @else {
  <p class="text-center"><em>Select a brand, then choose a template to edit or add a new template.</em></p>
}
