import { Component, OnDestroy, OnInit, Output, ViewChild, ElementRef, EventEmitter, Input, Renderer2 } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PlayerOptions } from '@models/PlayerOptions';
import { PlayerLogMessage, PlayerLogType } from '@models/PlayerLogging';
import { ConfigService } from '@services/config.service';
import { environment } from '../../../../environments/environment';

import { ConfigMap } from '@models/ClientConfig';

declare global {
  interface Window {
    TOP: any;
    players: any;
    readonly document: Document;
  }
}

@Component({
  selector: 'glass-player',
  templateUrl: './glass-player.component.html',
  styleUrls: ['./glass-player.component.css'],
  providers: [MessageService],
})
export class GlassPlayer implements OnInit, OnDestroy {
  @Output() playerMessage = new EventEmitter<PlayerLogMessage>();
  @Output() mediaLoaded = new EventEmitter<any[]>();
  @Input() mediaProfiles = [];
  @Input() noMargin = false;
  @ViewChild('parentContainer') parentContainer!: ElementRef;
  config!: ConfigMap;
  currentOpts: PlayerOptions;
  containerId = '';
  player!: any;
  manualBitrate = false;
  suppressedEvents: any[];
  playerVersion = '';
  selectedMediaProfile = '';
  constructor(
    private cs: ConfigService,
    private renderer: Renderer2,
  ) {
    this.containerId = 'pc_' + Date.now() + '_' + Math.random().toString();
    cs.CurrentConfig$.subscribe((n) => (this.config = n.config));
    this.currentOpts = {
      mediaId: '',
      companyId: '',
      assetId: '',
      adProfile: '',
      env: '',
      cappedBitrate: true,
      logging: false,
    };
    this.suppressedEvents = [window.TOP.Player.PlayerEventType.Media_Time_Changed, window.TOP.Player.PlayerEventType.Ad_Time_Changed, window.TOP.Player.PlayerEventType.Cue_State_Changed, window.TOP.Player.PlayerEventType.Model_Updated, window.TOP.Player.PlayerEventType.Media_Timed_Metadata_Parsed];
  }

  ngOnInit() {
    this.getPlayerVersion();
  }

  getPlayerVersion() {
    try {
      const v = new window.TOP.Player.exports.PlayerVersionHelper();
      this.playerVersion = v.libVersion;
    } catch (ex) {
      console.debug('error fetching player lib version');
    }
  }
  async ngOnDestroy() {
    if (this.player) {
      await this.destroyPlayer();
    }
  }

  async destroyPlayer() {
    try {
      if (this.player) {
        this.player.stop();
        this.player
          .destroy()
          .then((n: any) => {
            console.debug(n);
            return true;
          })
          .catch((ex: any) => {
            console.error('problem destroying player');
            console.error(ex);
            return false;
          });
        return true;
      }
      return true;
    } catch (ex) {
      console.debug(ex);
      return false;
    }
  }

  async resetPlayer() {
    await this.PlayMediaFromOpts(this.currentOpts);
  }

  async PlayMediaFromOpts(opts: PlayerOptions) {
    try {
      this.currentOpts = opts;
      if (this.player) await this.destroyPlayer();
      this.player = window.TOP.Player.create();
      if (this.currentOpts.mediaId?.toLowerCase().startsWith('http')) this.PlayByM3U8();
      else this.PlayMediaJson();
    } catch (ex) {
      console.debug(ex);
    }
  }

  PlayMediaJson() {
    const playerSetup = this.createSetupConfig();
    const entryOptions = this.createEntryOptions();

    this.player.setup(playerSetup);
    this.attachLogging();

    this.player.playByMediaJson(
      {
        mediaId: this.currentOpts.mediaId,
        serviceUrl: this.generateServiceUrl(),
      },
      entryOptions,
    );
  }
  setMediaProfile(id: string) {
    this.selectedMediaProfile = id;

    // if this is our first time setting the bitrate manually we need to restart the player
    // because otherwise capBitrateToSize will override the selected profile.
    if (this.manualBitrate == false) {
      this.manualBitrate = true;
      this.currentOpts.cappedBitrate = false;

      // now when we reset the player the profile will be loaded once we receive the MediaLoaded
      // message

      this.PlayMediaFromOpts(this.currentOpts);
    } else {
      // if we're already in manual mode just update the profileId

      this.player.setMediaProfile(id);
    }
  }
  /**
   * mothballed but possible revivable in the future
   */
  PlayByM3U8() {
    const playerSetup = this.createSetupConfig();
    let adConfig;

    if (this.currentOpts.adProfile) {
      adConfig = window.TOP.Player.PlayerConfigBuilder.forPlay().withAds({ profile: this.currentOpts.adProfile }).build();
    } else {
      adConfig = undefined;
    }

    const entryOptions = window.TOP.Player.ContentEntryBuilder.forEntryOptions().withPlayConfigOverrides(adConfig).build();

    this.player.setup(playerSetup);

    this.attachLogging();

    this.player.play(this.currentOpts.mediaId, entryOptions);
  }
  /**
   *
   * Used for overrides. If the playeroptions have a CDN alter the serviceURL to set the default profile.
   *
   * @returns custom medium service URL
   */
  generateServiceUrl(): string {
    return '{host}{versionId}/media/{mediaId}/{platform}' + (this.currentOpts.cdn ? `?cdnProfile=${this.currentOpts.cdn}` : '');
  }

  attachLogging() {
    this.player.events.listen((type: any, result: any) => {
      if (
        this.suppressedEvents.some((n) => {
          return n == type;
        })
      )
        return;

      // generic megalogging
      if (type === window.TOP.Player.PlayerEventType.Message_From_UI) {
        console.debug(`[${type}] "${result.name}"`, result.payload);
      }
      console.debug(`[${type}]`, this.getResultMessage(type, result));

      // glass specific logging
      switch (type) {
        case 'mediaStarting':
          {
            if (result && result.url) {
              this.playerMessage.emit({
                type: PlayerLogType.CdnDetected,
                value: result.url.split('/')[2],
              });
            }
          }
          break;
        case 'mediaLoaded':
          {
            if (!this.manualBitrate) this.mediaLoaded.emit(this.player.model.mediaProfiles);
            else this.player.setMediaProfile(this.selectedMediaProfile);
          }
          break;
        case 'cueProcessed':
          // Check for pure SCTE, ignore Yospace
          if (result.cue && result.cue.type == 'SCTE')
            this.playerMessage.emit({
              type: PlayerLogType.Scte,
              value: result,
            });
          else console.debug('non SCTE cue - ');
          console.debug(result);
          break;
        default:
          break;
      }
    });
  }

  getResultMessage = (type: any, result: any) => {
    if (Object.prototype.hasOwnProperty.call(result, 'cue')) {
      if (type == window.TOP.Player.PlayerEventType.Cue_Processed) return `${result.cue.id}, ${this.getCueMessage(result)}, trigger: ${result.cue.data.range.start}`;
      else return `${result.cue.id}, ${this.getCueMessage(result)}`;
    } else if (Object.prototype.hasOwnProperty.call(result, 'currentState')) return result.previousState + ' -> ' + result.currentState;
    else if (Object.prototype.hasOwnProperty.call(result, 'currentValue')) return result.previousValue + ' -> ' + result.currentValue;
    else if (Object.prototype.hasOwnProperty.call(result, 'time')) return result.time;
    else if (Object.prototype.hasOwnProperty.call(result, 'volume')) return result.volume;
    else if (Object.prototype.hasOwnProperty.call(result, 'muted')) return result.muted;
    else if (Object.prototype.hasOwnProperty.call(result, 'command')) return result.command;
    else if (Object.prototype.hasOwnProperty.call(result, 'profile')) return result.profile.bitrate;
    else if (Object.prototype.hasOwnProperty.call(result, 'marker')) return JSON.stringify(result.marker);
    else if (Object.prototype.hasOwnProperty.call(result, 'settings')) return JSON.stringify(result.settings);
    else if (Object.prototype.hasOwnProperty.call(result, 'description')) return result.description;
    else if (Object.prototype.hasOwnProperty.call(result, 'error')) return result.error.toString();

    return result;
  };

  getCueMessage = (result: any) => {
    const type = result.cue.type;
    let msg = type;
    if (type == window.TOP.Player.cues.CueType) {
      const descriptors = result.cue.data.content.metadata.splice_descriptors.map((desc: any) => {
        return desc.segmentation_type_id_name;
      });

      if (descriptors.length > 0) msg += ` (${descriptors.join(' && ')})`;
    }
    return msg;
  };
  createEntryOptions() {
    let adConfig;
    // take care of entry options
    if (this.currentOpts.adProfile) {
      adConfig = window.TOP.Player.PlayerConfigBuilder.forPlay().withAds({ profile: this.currentOpts.adProfile }).build();
    } else {
      adConfig = undefined;
    }
    let entryOptions = window.TOP.Player.ContentEntryBuilder.forEntryOptions().withPlayConfigOverrides(adConfig);

    if (this.currentOpts.token) entryOptions = entryOptions.withAccessTokenType('jws').withAccessToken(this.currentOpts.token.jws);
    return entryOptions.build();
  }

  createPlayerElements() {
    const vc = document.createElement('div');
    try {
      this.renderer.removeChild(this.parentContainer.nativeElement, window.document.getElementById(this.containerId));
      this.containerId = 'pc_' + Date.now() + '_' + Math.random().toString();
      vc.id = this.containerId;
      vc.className = 'video-container';
      this.parentContainer.nativeElement.prepend(vc);
    } catch (ex) {
      console.debug(ex);
      return false;
    }
    return true;
  }

  createSetupConfig() {
    this.createPlayerElements();
    return window.TOP.Player.PlayerConfigBuilder.forSetup()
      .withContainer(document.getElementById(this.containerId))
      .withMetadata({
        appId: this.config.key,
        env: this.currentOpts.env,
        companyId: this.currentOpts.companyId,
      })
      .withPlayback({
        autoPlay: true,
        autoPlayOptions: { forceMedia: true },
        muted: this.currentOpts.muted || false,
        volume: this.currentOpts.volume || 0.5,
      })
      .withMedia({ capBitrateToSize: this.currentOpts.cappedBitrate ?? true })
      .withDebug({ enabled: environment?.topDebug || false })
      .build();
  }
}
