<div class="flex flex-row w-[80%] pl-6 gap-x-6">
  <div class="self-center text-center">
    <p-checkbox inputId="enableForm" [(ngModel)]="overrideEnabled" [binary]="true" styleClass="mr-2" />
    <label class="text-sm uppercase" for="enableForm">Override</label>
  </div>
  <div class="flex-auto p-float-label" [ngClass]="overrideEnabled ? 'opacity-100' : 'opacity-50'">
    <input id="customMediaId" inputId="mediaIdText" [(ngModel)]="customMediaId" (change)="invalidData()" required="true" pInputText [disabled]="!overrideEnabled" class="w-full opacity-100 disabled:opacity-50" type="text" />
    <label for="mediaIdText">Media ID / URL</label>
  </div>
  <div class="flex-auto p-float-label" [ngClass]="overrideEnabled ? 'opacity-100' : 'opacity-50'">
    <p-dropdown [options]="envs" id="customEnv" [(ngModel)]="customEnv" (onChange)="invalidData()" [required]="false" optionLabel="title" optionValue="env" [disabled]="!overrideEnabled" [autoDisplayFirst]="false" [showClear]="true" styleClass="w-full disabled:opacity-50 opacity-100" inputId="envDropdown"></p-dropdown>
    <label for="envDropdown">Env.</label>
  </div>
  <div class="flex-auto p-float-label" [ngClass]="overrideEnabled ? 'opacity-100' : 'opacity-50'">
    <p-dropdown [options]="cdnOptions" [(ngModel)]="customCdn" inputId="customCdn" [disabled]="!overrideEnabled" styleClass="w-full inputfield disabled:opacity-50 opacity-100" [autoDisplayFirst]="false" [showClear]="true" ></p-dropdown>
    <label for="customCdn">CDN</label>
  </div>
  <div class="flex-auto p-float-label" [ngClass]="overrideEnabled ? 'opacity-100' : 'opacity-50'">
    <input id="customAdProfile" inputId="adProfileText" [(ngModel)]="customAdProfile" pInputText [disabled]="!overrideEnabled" class="w-full opacity-100 inputfield disabled:opacity-50" type="text" />
    <label for="adProfileText">Ad Profile</label>
  </div>
  <div>
    <p-button [class]="this.overrideEnabled ? (this.formDisabled ? 'opacity-50' : 'opacity-100') : 'opacity-50'" [disabled]="this.formDisabled && !this.overrideEnabled" (onClick)="playOverride()" label="GO" value="GO"></p-button>
  </div>
</div>
