<div class="flex items-center cursor-pointer badge-container">
  <!-- <p class="mr-3 font-sans text-xs font-extralight">Hello {{ this.firstName }}</p> -->
  <a
    class="text-xl p-[10px] min-w-[44px] min-h-[45px] align-middle rounded-md bg-glass-100 text-glass-800"
    (click)='avatarMenu.toggle($event)'>{{this.initials}}</a>
</div>
<p-tieredMenu
  [styleClass]="'tiered-menu'"
  showTransitionOptions="100ms"
  [model]='items'
  [popup]='true'
  #avatarMenu></p-tieredMenu>
