import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, timer, BehaviorSubject } from 'rxjs';
import { ClientConfig } from '@models/ClientConfig';
import { UserProfile } from '@models/UserProfile';
import { OktaAuthStateService } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private currentConfig: BehaviorSubject<any>;
  private currentProfile: BehaviorSubject<any>;
  public CurrentConfig$: Observable<ClientConfig>;
  public CurrentProfile$: Observable<UserProfile>;
  private init = false;
  constructor(
    private http: HttpClient,
    private ss: OktaAuthStateService,
  ) {
    this.currentConfig = new BehaviorSubject<any>({});
    this.CurrentConfig$ = this.currentConfig.asObservable();

    this.currentProfile = new BehaviorSubject<any>({});
    this.CurrentProfile$ = this.currentProfile.asObservable();

    ss.authState$.subscribe((n) => {
      if (!n.isAuthenticated) return;
      if (!this.init) {
        this.init = true;

        timer(0, 30000).subscribe(() => {
          this.http.get<any>(`${environment.glassApiUrl}/v2/config`).subscribe((config) => {
            this.currentConfig.next(config);
          });
        });

        timer(0, 30000).subscribe(() => {
          this.http.get<any>(`${environment.glassApiUrl}/v2/userProfile`).subscribe((profile) => {
            this.currentProfile.next(profile);
          });
        });
      }
    });
  }
}
