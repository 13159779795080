import { Component, OnInit } from '@angular/core';
import { WebRTCOptions } from '@models/PlayerOptions';
import { WowzaWebRTCPlay } from './WowzaWebRTCPlay';

@Component({
  selector: 'app-webrtc-player',
  templateUrl: './webrtc-player.component.html',
  styleUrls: ['./webrtc-player.component.css']
})

export class WebRtcPlayer implements OnInit {
  public playerElementId: string;
  public settings: WebRTCOptions = {};
  private playerState: any = {};
  private player: WowzaWebRTCPlay;

  constructor() { 
    this.playerElementId = 'rtc_' + Date.now() + '_' + Math.random().toString();
    this.player = new WowzaWebRTCPlay();

  }

  ngOnInit() {
  }

  launchPlayer(config: WebRTCOptions) {
    this.initPlayer();
    this.settings = JSON.parse(JSON.stringify(config));
    this.player.set({
      applicationName: config.appName,
      sdpURL: config.streamUrl,
      streamName: config.streamName
    })
    this.player.play();
    return;
  }

  handlePlayerError(e: any) { 
    console.debug(e);
  }

  resetPlayer(): void { 
    this.player.stop();
    this.launchPlayer(this.settings);
  }

  ngOnDestroy() { 
    this.player.stop();
    
  }

  initPlayer() { 
    this.player.on({
      onError: this.handlePlayerError,
      onStateChanged: (state:any) => { 
        if(state.connectionState === 'connected')
          this.playerState.playing = true;
        else 
          this.playerState.playing = false;
      }
    })
    const eleRef = window.document.getElementById(this.playerElementId);

    this.player.set({
      videoElementPlay: eleRef,
    });

  }


}
