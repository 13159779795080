const sampleUsers = {
  ldoUsers: [
    { name: 'Antonio Johnson', email: 'antonio.johnson@warnermedia.com', initials: 'aj' },
    { name: 'Leah Siddall', email: 'leah.siddall@warnermedia.com', initials: 'ls' },
    { name: 'Sheldon McLean', email: 'Sheldon.Mclean@wbd.com', initials: 'sm' },
    { name: 'Thad Johnson', email: 'thad.johnson@warnermedia.com', initials: 'tj' },
    { name: 'TJ Dorsey', email: 'tj.dorsey@warnermedia.com', initials: 'tjd' },
    { name: 'Xan Gerson', email: 'xan.gerson@warnermedia.com', initials: 'xg' },
  ],
  lotUsers: [
    { name: 'Kenneth Sweat', email: 'kenneth.sweat@warnermedia.com', initials: 'ks' },
    { name: 'Mike Crowley', email: 'mike.crowley@warnermedia.com', initials: 'mc' },
  ],
  vtUsers: [
    { name: 'Aitor Recalde', email: 'aitor.recalde@warnermedia.com', initials: 'ar' },
    { name: 'Hiko Sakamoto', email: 'hiko.sakamoto@warnermedia.com', initials: 'hs' },
    { name: 'Issac Thor', email: 'issac.thor@warnermedia.com', initials: 'it' },
    { name: 'Jonathan Brooks', email: 'jonathan.brook@warnermedia.com', initials: 'jb' },
    { name: 'Kenneth Sweat', email: 'kenneth.sweat@warnermedia.com', initials: 'ks' },
    { name: 'Michael Edmondson', email: 'michael.edmondson@warnermedia.com', initials: 'me' },
    { name: 'Roque Murgueytio', email: 'roque.murgueytio@warnermedia.com', initials: 'rm' },
    { name: 'Scott Palmer', email: 'scott.palmer@warnermedia.com', initials: 'sp' },
  ],
  ecUsers: [
    { name: 'Aitor Recalde', email: 'aitor.recalde@warnermedia.com', initials: 'ar' },
    { name: 'Hiko Sakamoto', email: 'hiko.sakamoto@warnermedia.com', initials: 'hs' },
    { name: 'Jonathan Brooks', email: 'jonathan.brook@warnermedia.com', initials: 'jb' },
    { name: 'Ricky Chandler', email: 'ricky.chandler@warnermedia.com', initials: 'rc' },
    { name: 'Roque Murgueytio', email: 'roque.murgueytio@warnermedia.com', initials: 'rm' },
    { name: 'Scott Palmer', email: 'scott.palmer@warnermedia.com', initials: 'sp' },
  ],
};
export default sampleUsers;
