import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, switchMap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class VideoService {
  eventTimer: any;
  public CurrentEvents$: Observable<any>;
  public CdnOptions$: Observable<any>;

  constructor(private http: HttpClient) {
    this.CurrentEvents$ = timer(0, 30_000).pipe(
      switchMap(() => this.http.get<any>(`${environment.glassApiUrl}/v1/currentevents`)),
      shareReplay(1),
    );
    this.CdnOptions$ = timer(0, 60_000).pipe(
      switchMap(() => this.http.get<any>(`${environment.glassApiUrl}/v1/config/cdns`)),
      shareReplay(1),
    );
  }

  getRunningEvents() {
    return this.CurrentEvents$;
  }

  getCDNOptions() {
    return this.CdnOptions$;
  }

  getEvents() {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events`);
  }

  getEvent(eventId: number) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events/${eventId}`);
  }

  getTokenForEvent(eventId: number) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/token/${eventId}`);
  }

  getTokenForMedium(mediaId: string, env: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/token/${mediaId}/${env}`);
  }

  getMediumData(mediaId: string, env: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1//medium/${mediaId}/${env}`);
  }

  getStreamStatus(streamIds: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/stream/health/${streamIds}`);
  }

  getEdgeVersions() {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/edge/versions`);
  }

  getScteForEvent(eventId: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events/${eventId}/logs`);
  }

  getQuickVodReport(brand: string, selectedTimeFrame: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/quickVod/${brand}/${selectedTimeFrame}`);
  }
}
