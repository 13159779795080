import { Pipe, type PipeTransform } from '@angular/core';
import spacetime from 'spacetime';

/**
 * Support format types: `'spacetime'` tokens or `'unix'` patterns
 * @default 'spacetime'
 */
export type FormatType = 'spacetime' | 'unix';

const ET = 'America/New_York';

const FORMATS: Record<string, string> = {
  '{time-24-pad}': '{hour-24-pad}:{minute-pad}',
};

const tokenRegEx = /\{[^}]*\}/g;

const interpolateFormat = (format: string) =>
  format.replace(tokenRegEx, (token: string) => {
    if (token in FORMATS) return FORMATS[token];
    return token;
  });

@Pipe({
  name: 'toET',
})
/**
 * Accepted formats are spacetime-supported versions, unless you set `unixFormat` true.
 * See https://github.com/spencermountain/spacetime/wiki/Formatting
 * Warnings:
 *   - `{time-24}` does not pad; use `{hour-24-pad}:{minute-pad}` or my hack `{time-24-pad}`
 */
export class ToETPipe implements PipeTransform {
  transform(value: Date | number | string | null | undefined, format: string, formatType: FormatType = 'spacetime'): string {
    const spacetimeDate = (() => {
      switch (typeof value) {
        case 'string': // '2020-02-20T02:20:22Z'
          if (value === '') return undefined;
          return spacetime(value).goto(ET);
        case 'number': // ms since epoch
          if (isNaN(value) || !isFinite(value)) return undefined;
          return spacetime(value).goto(ET);
        case 'object': // Date
          if (!value) return undefined;
          return spacetime(value, ET);
        default:
          return undefined;
      }
    })();
    if (!spacetimeDate) return '';
    if (formatType === 'unix') return spacetimeDate.unixFmt(format);
    return spacetimeDate.format(interpolateFormat(format));
  }
}
