import { Component } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ConfigService, ConfirmationService],
})
export class AppComponent {
  title = 'Glass';
  constructor(private cs: ConfigService) {
    return;
  }
}
