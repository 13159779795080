import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { primeng_modules } from '../primeng-imports';
import { CustomMediaComponent } from './custom-media/custom-media.component';
import { GlassPlayer } from './glass-player/glass-player.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { LogoutComponent } from './logout/logout.component';
import { BadgeComponent } from './badge/badge.component';
import { WebRtcPlayer } from './webrtc-player/webrtc-player.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { DragulaModule } from 'ng2-dragula';
import { ToETPipe } from '../pipes/to-et.pipe';
import { SplitCamelCasePipe } from '../pipes/split-camel-case.pipe';

const components = [GlassPlayer, CustomMediaComponent, LogoutComponent, BadgeComponent, WebRtcPlayer, TemplateEditorComponent, ToETPipe, SplitCamelCasePipe];

@NgModule({
  declarations: components,
  imports: [CommonModule, DragulaModule.forRoot(), DropdownModule, ButtonModule, FormsModule, primeng_modules],
  exports: components,
})
export class SharedComponentsModule {}
