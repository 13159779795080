<div id="page-skeleton">
  @if (this.url !== '' && this.url !== '/') {
    <header id="header-area">
      <section id="top-nav-bar">
        <div>
          <!-- brand logo -->
          <a pTooltip="Home" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip"
            routerLink="/"
            >
            <img alt="" width="37" height="36" src="/assets/wbd.svg">
            <span class="text-gold">GLASS</span>
          </a>
        </div>
        <div>
          <!-- core links -->
          <a pTooltip="GLASSwatch" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip"
            routerLink="/watch" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <i class="material-icons material-symbols-rounded text-gold">videocam</i>
            <div>GLASSwatch</div>
          </a>
          <a pTooltip="GLASSops" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip"
            *oktaHasAnyGroup="this.opsRole" routerLink="/ops/v2" routerLinkActive="active">
            <i class="material-icons material-symbols-rounded text-gold">inventory</i>
            <div>GLASSops</div>
          </a>
        <a pTooltip="GLASStools" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip" routerLink="/tools"
          routerLinkActive="active"><i class="material-icons material-symbols-rounded text-gold">contract</i>
          <div>GLASStools</div>
        </a>
      </div>
      <div>
        <!-- utilities -->
        <a pTooltip="FAQ" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip" routerLink="/faq"
          routerLinkActive="active"><i class="material-icons material-symbols-rounded text-gold">quiz</i>
          <div>FAQ</div>
        </a>
        <!-- <a pTooltip="Documentation" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip"
        routerLink="/documentation" routerLinkActive="active"><i
      class="material-icons material-symbols-rounded text-gold">unknown_document</i>
      <div>Documentation</div>
    </a> -->
    <div class="h-full">
      <p-divider layout="vertical"></p-divider>
    </div>
    <a pTooltip="Admin" tooltipPosition="bottom" [tooltipDisabled]="disableNavbarTooltip"
      *oktaHasAnyGroup="this.adminRole" routerLink="/admin" routerLinkActive="active"><i
    class="material-icons material-symbols-rounded text-gold">admin_panel_settings</i>
    <div>Admin</div>
  </a>
  <glass-badge class="self-center ml-2"></glass-badge>
</div>
</section>
</header>
}

<section id="content-area">
  <section id="main-content">
    <router-outlet></router-outlet>
  </section>
</section>

<footer id="footer-area">
  <span>&copy;{{ this.year }} Warner Bros. Discovery. All Rights Reserved.</span>
  @if (this.version) {
    <span>GLASSversion: {{ this.version }}</span>
  }
</footer>
</div>