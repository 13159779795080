/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { ConfigService } from '@services/config.service';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, take, withLatestFrom } from 'rxjs/operators';

import { environment as env } from '../../../../environments/environment';
import { UserProfile } from '@models/UserProfile';

@Injectable({
  providedIn: 'root',
})
export class OpsGuard implements OnDestroy, CanLoad, CanActivate {
  private routeParamSubscription: Subscription = new Subscription();
  hasOpsRole$: Observable<boolean> = new Observable();
  hasBrandRoles$: Observable<boolean> = new Observable();

  activeBrand: string = '';
  constructor(
    private readonly stateService: OktaAuthStateService,
    private readonly router: Router,
    private readonly configService: ConfigService,
    private readonly activeRoute: ActivatedRoute,
  ) {
    // only really check brand roles when we're drilled into a brand's event or ops pages ...
    this.routeParamSubscription = this.activeRoute.paramMap.subscribe((p) => {
      this.activeBrand = p.get('brand') || '';
    });

    this.hasOpsRole$ = this.stateService.hasAnyGroups(env.opsRole).pipe(shareReplay(1));

    this.hasBrandRoles$ = this.configService.CurrentProfile$.pipe(
      take(1),
      map((profile: UserProfile) => {
        // bail early if we're in ops but we're not in a brand section, i.e. we're on a landing page or other "public" page
        if (this.activeBrand === '') return true;

        // otherwise check that the brand param in the URL exists for this user
        return (profile.opsProfile.globalRole ?? '') !== '' || profile.opsProfile.brandRoles.some((p: any) => p.brandCode === this.activeBrand);
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.hasOpsRole$.pipe(
      take(1),
      withLatestFrom(this.hasBrandRoles$),
      map(([hasOpsRole, hasBrandRole]) => {
        if (hasOpsRole || hasBrandRole) return true;

        // otherwise ...
        this.router.navigate(['/']);
        return false;
      }),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.hasOpsRole$.pipe(
      take(1),
      withLatestFrom(this.hasBrandRoles$),
      map(([hasOpsRole, hasBrandRole]) => {
        if (hasOpsRole || hasBrandRole) return true;

        // otherwise ...
        this.router.navigate(['/']);
        return false;
      }),
    );
  }

  isOpsUser(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.stateService.hasAnyGroups(env.opsRole).pipe(
      take(1),
      map((isIngroup) => {
        if (isIngroup) return true;
        this.router.navigate(['/']);
        return false;
      }),
    );
  }
  ngOnDestroy(): void {
    if (this.routeParamSubscription) this.routeParamSubscription.unsubscribe();
  }
}
