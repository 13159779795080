import { Component, Input, EventEmitter, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomMediaOptions, PlayerOptions } from '@models/PlayerOptions';
import { VideoService } from '@services/video.service';

interface Envs {
  title: string;
  env: string;
}

@Component({
  selector: 'custom-media',
  templateUrl: './custom-media.component.html',
  styleUrls: ['./custom-media.component.css'],
  providers: [],
})
export class CustomMediaComponent implements OnInit, OnChanges {
  @Output() submitOverride = new EventEmitter<CustomMediaOptions>();
  @Output() selectMediaSelected = new EventEmitter();
  @Input() mediaTitle = 'Media Id / URL';
  @Input() boundOpts: PlayerOptions | undefined;

  envs: Envs[];
  selectedCDN: any;
  customMediaId = '';
  customAdProfile = '';
  customEnv = '';
  customCdn = '';
  cdnOptions: any[];
  formDisabled = false;
  overrideEnabled = false;

  constructor(private eventService: VideoService, private router: Router) {
    this.cdnOptions = [];
    this.envs = [
      { title: 'Production', env: 'prod' },
      { title: 'ITE', env: 'ite' },
    ];
    this.formDisabled = true;
  }

  invalidData() {
    try {
      const isValidUrl = this.customMediaId != '' && this.customMediaId.toLowerCase().startsWith('http');
      const isValidMediaId = !isValidUrl && this.customMediaId != '';
      const isValidEnv = !!this.customEnv;
      this.formDisabled = !(isValidUrl || isValidMediaId) || !isValidEnv;
    } catch (ex) {
      console.debug(ex);
    }
  }

  playOverride() {
    this.submitOverride.emit({
      mediaId: this.customMediaId,
      adProfile: this.customAdProfile,
      env: this.customEnv,
      cdn: this.customCdn,
    });
  }

  displaySelectMedia() {
    this.selectMediaSelected.emit(true);
  }

  ngOnInit() {
    this.eventService.getCDNOptions().subscribe((cdnConfig) => {
      this.cdnOptions = cdnConfig.cdns.map((m: any) => {
        return {
          label: m.label,
          value: m.value,
          disabled: m.enabled === false,
        };
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['boundOpts']) {
      const nv = changes['boundOpts'].currentValue;
      this.customMediaId = nv.mediaId || '';
      this.customEnv = nv.env;
      this.customAdProfile = nv.adProfile;
      this.customCdn = nv.cdn;
    }
  }
}
