import { Pipe, type PipeTransform } from '@angular/core';

const wordDelineationPattern = /([a-z])([A-Z])/g;

@Pipe({
  name: 'splitCamelCase',
})
export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value.replace(wordDelineationPattern, '$1 $2');
  }
}
