/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OktaAuthStateService } from '@okta/okta-angular';
import { environment as env } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad, CanActivate {
  constructor(
    private stateService: OktaAuthStateService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.stateService.hasAnyGroups(env.adminRole).pipe(
      take(1),
      map((isIngroup) => {
        if (isIngroup) return true;
        this.router.navigate(['/']);
        return false;
      }),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.stateService.hasAnyGroups(env.adminRole).pipe(
      take(1),
      map((isIngroup) => {
        if (isIngroup) return true;
        this.router.navigate(['/']);
        return false;
      }),
    );
  }
}
